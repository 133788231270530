import gsap from 'gsap'
const HotSpots = () => {
  let activeHighlight = []
  var activeHotspots = []

  const arrayEquals = (a, b) => {
    return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index])
  }

  const getHotspotId = (_e) => {
    const id = _e.id.split('A')[1] ? _e.id.split('A')[1] : _e.id.split('T')[1]
    // adding a split at the end(if we have id) to account for poor inconsistent assets, some text id's come in with a dash - T4-9 some dont - T4
    return id ? id.split('-')[0] : ''
  }

  const getItemsToHighlight = (_hotspotId) => {
    const itemsToHiglight = []
    const selectedItems = document.querySelectorAll('path[id]')

    selectedItems.forEach(function (_item) {
      if (_item.id.includes(_hotspotId)) {
        itemsToHiglight.push(_item)
      }
    })
    return itemsToHiglight
  }

  const removeHotspotHighlight = (_highlightItems) => {
    const hotspotIndex = activeHotspots.findIndex(_hsGroup => arrayEquals(_hsGroup, _highlightItems))
    // make sure the thing being unhighlighted isnt an active item (items are set to active once clicked),
    // return if its active
    if (hotspotIndex >= 0) { return }

    // remove hilights from _highlightItems array
    _highlightItems.forEach(item => {
      gsap.to(item, { duration: 0, fill: '', opacity: 0 })
    })
    // re activeate all hotspots since something that was removed could have related to an active highlight
    if (activeHotspots.length > 0) { activeHotspots.forEach(_hsGroup => _hsGroup.forEach(_hsItem => gsap.to(_hsItem, { duration: 0, fill: '#ee3229', opacity: 0.2 }))) }
  }

  const addHotspotHighlight = (_highlightItems) => {
    _highlightItems.forEach(item => {
      gsap.to(item, { duration: 0, fill: '#ee3229', opacity: 0.2 })
    })
  }

  const hotSpotOut = (e) => {
    const hotspotId = getHotspotId(e)
    if (!hotspotId) { return }

    removeHotspotHighlight(activeHighlight)
    activeHighlight = []
  }

  const hotSpotOver = (e) => {
    const hotspotId = getHotspotId(e)
    if (!hotspotId) { return }

    if (activeHighlight.length > 0) {
      removeHotspotHighlight(activeHighlight)
    }
    const highlights = getItemsToHighlight(hotspotId)
    activeHighlight = highlights
    addHotspotHighlight(highlights)
  }
  const highlightSelectedPartTiles = (hotspotId) => { // update number of selections value on main categorys
    const itemsToHiglight = []
    const selectedItems = document.getElementsByClassName('hotspot-' + hotspotId)
    selectedItems[0].parentElement.parentElement.getElementsByClassName('num-selected')[0].innerHTML = `(${selectedItems.length} selected)`
    selectedItems.forEach(function (_item) {
      _item.classList.add('hotspot-selected')
    })
    return itemsToHiglight
  }
  const removeSelectedPartTiles = (hotspotId) => { // update number of selections value on main categorys
    const itemsToHiglight = []
    const selectedItems = document.getElementsByClassName('hotspot-' + hotspotId)
    selectedItems[0].parentElement.parentElement.getElementsByClassName('num-selected')[0].innerHTML = ''
    selectedItems.forEach(function (_item) {
      _item.classList.remove('hotspot-selected')
    })
    return itemsToHiglight
  }
  const hotSpotClick = (e) => {
    const hotspotId = getHotspotId(e)

    if (!hotspotId) { return }

    const clickedHotspot = document.querySelector(`[data-hotspot-id="${hotspotId}"]`)
    const clickedHotspotGroup = getItemsToHighlight(hotspotId)
    // check if this hotspot was activated prior
    const hotspotIndex = activeHotspots.findIndex(_hsGroup => arrayEquals(_hsGroup, clickedHotspotGroup))
    // if hotspot is in activeHotspots, remove it, it was activated so we know this click is to deactivate the hotspot
    // else we will add it to activeHotspots array
    if (!clickedHotspot) return
    else if (hotspotIndex >= 0) {
      clickedHotspot.classList.remove('hotspot-active')
      activeHotspots.splice(hotspotIndex, 1)
      removeSelectedPartTiles(hotspotId)
      return
    }
    // DO THIS STUFF TO ACTIVATE THE CLICKED HOTSPOT
    activeHotspots.push(clickedHotspotGroup)
    clickedHotspot.classList.add('hotspot-active')

    const scrollTopSelector = `[data-hotspot-id="${hotspotId}"]`
    const scrollToTop = document.querySelector(scrollTopSelector).getBoundingClientRect().top + window.scrollY
    if (scrollToTop) {
      gsap.to([document.documentElement, document.body], {
        scrollTop: scrollToTop - 250
      })
    }

    highlightSelectedPartTiles(hotspotId)
  }

  const init = () => {
    document.querySelectorAll('rect[onclick], path[onclick], text[onclick]')
      .forEach((el) => {
        el.setAttribute('onclick', 'window.mfmicrosite.HotSpots.click(this)')
      })
    document.querySelectorAll('rect[onmouseleave], path[onmouseleave], text[onmouseleave]')
      .forEach((el) => {
        el.setAttribute('onmouseleave', 'window.mfmicrosite.HotSpots.out(this)')
      })
    document.querySelectorAll('rect[onmouseenter], path[onmouseenter], text[onmouseenter]')
      .forEach((el) => {
        el.setAttribute('onmouseenter', 'window.mfmicrosite.HotSpots.over(this)')
      })
  }
  window.mfmicrosite = window.bvaccel || {}
  window.mfmicrosite.HotSpots = {}
  window.mfmicrosite.HotSpots.out = hotSpotOut
  window.mfmicrosite.HotSpots.over = hotSpotOver
  window.mfmicrosite.HotSpots.click = hotSpotClick
  return { init }
}
export const hotSpots = HotSpots()
