<template>
    <el-button
      :model="buttonTitle"
      :type="type"
      round
      :class="{ nourl:url=='NA' }"
      @click.stop="urlOnClick"
      ><slot/>{{ title }}</el-button>
</template>

<script>
export default {
  props: {
    type: { type: String, default: 'primary' },
    url: { type: String, default: 'NA' },
    urlWidth: { type: String, default: '142px' },
    title: { type: String }
  },
  setup (props) {
    const urlOnClick = () => {
      if (props.url !== 'NA') { window.open(`${props.url}`, '_blank') }
    }
    return { urlOnClick }
  }
}
</script>

<style lang="scss">
.el-button {
  margin-left: 0px !important;
  color: $mf-btn-font-color !important;
  border: none !important;
  font-weight: bold !important;
  &.nourl{
    opacity: .3;cursor:default;
  }
  &.el-button--primary {
    background-color: var(--btn-bg-color) !important;
  }
}
.el-button:hover {
  color: $mf-btn-font-color !important;
  &.el-button--text {
    color: var(--btn-bg-hover-color) !important;
  }
  &.el-button--primary {
    background-color: var(--btn-bg-hover-color) !important;
  }
}

.el-button--text {
  padding: 0 !important;
  color: var(--link-color) !important;
  i {
    margin-right: 5px;
  }
}
.icon-right{
  i{
    float:right;
  }
  span{
    margin-right: 5px;
  }
}
</style>
