<template>
  <div class="collection-content">
    <FixedHeader :title="collectionTitle">
      <div class="hide-show-diagram" @click="hideDiagramClicked()" v-if="hasDiagram">
        <Button
          class="secondary-nav-link"
          type="text"
          icon="fas icon-fa-eye-slash"
          v-if="diagramIsShown"
          >HIDE DIAGRAM</Button
        >

        <Button class="secondary-nav-link" type="text" icon="fas icon-fa-eye" v-else
          >SHOW DIAGRAM</Button
        >
      </div>
    </FixedHeader>

    <div :class="{diagramhidden:!diagramIsShown}" class="header-spacer"></div>
    <HotSpot
      ref="hotspotRef"
      :hotspotData="hotspotData"
      :show="diagramIsShown"
      @HOTSPOT_DIAGRAM_LOADED='hotspotDiagramLoaded'
      @HOTSPOTS_VIEW_INITED='hotspotsViewInited'
    />
    <PartSection
      class=""
      :partCategoriesData="partCategoriesData"
      v-if="partCategoriesData"
    />
  </div>
</template>
<script>
import HotSpot from '@/components/collection/HotSpot'
import PartSection from '@/components/collection/PartSection'
import { useGetters } from '@/lib/helpers'
import { ref, computed, inject, watchEffect } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Button from '@/components/buttons/Button'
import FixedHeader from '@/components/global/FixedHeader'
import gsap from 'gsap'
import { ymmSelectionData } from '@/lib/ymm/YMMSelectionData'
export default {
  components: { HotSpot, PartSection, Button, FixedHeader },
  setup () {
    const { ymmCollectionQueryObj, ymmCollectionData } = useGetters([
      'ymmCollectionQueryObj',
      'ymmCollectionData'
    ])
    const route = useRoute()
    const hotspotData = ref(false)
    const hotspotRef = ref(null)
    const partCategoriesData = ref(false)
    const diagramIsShown = ref(false)
    const hasDiagram = ref(false)
    const GlobalYMMQualifiers = inject('YMMQualifiersGlobal')
    const router = useRouter()

    const hideDiagramClicked = () => {
      diagramIsShown.value = !diagramIsShown.value
    }

    const collectionTitle = computed(() => {
      let title = 'Please Select a Car Config'
      if (ymmCollectionData.value.collectionName !== '') title = `${ymmCollectionData.value.collectionName} PARTS`
      return title
    })

    const init = async () => {
      // check the store if we have the information from prior search
      const storeHasRequestedData = decodeURIComponent(route.fullPath) === decodeURIComponent(ymmCollectionQueryObj.value.slug)
      if (storeHasRequestedData) {
        hotspotData.value = ymmCollectionData.value.hotspotData
        partCategoriesData.value = ymmCollectionData.value.partCategories
        return
      }
      const dataFromURLSelection = await ymmSelectionData.getPartDataFromUrlQuery(route.query)
      if (dataFromURLSelection) {
        GlobalYMMQualifiers.value.setupQualifiers({ ...dataFromURLSelection })
        return
      }
      router.push({ name: 'Home' })
    }

    const resetData = () => {
      diagramIsShown.value = hasDiagram.value = partCategoriesData.value = hotspotData.value = false
    }

    watchEffect(() => {
      const isCollectionPage = route.fullPath.includes('collection')
      if (GlobalYMMQualifiers.value.qualifiersOpen === false && isCollectionPage) init()
      else resetData()
    })

    const hotspotDiagramLoaded = () => {
      diagramIsShown.value = true
      hasDiagram.value = true
    }

    const hotspotsViewInited = () => {
      gsap.to('.part-section-container', { duration: 1, autoAlpha: 1 })
    }
    return {
      hotspotData,
      hotspotRef,
      partCategoriesData,
      ymmCollectionData,
      diagramIsShown,
      hideDiagramClicked,
      hotspotDiagramLoaded,
      hasDiagram,
      hotspotsViewInited,
      collectionTitle
    }
  }
}
</script>

<style lang="scss">
.collection-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  min-width: 1024px;
  .header-spacer{
    margin-top:calc(#{$fixed-header-height} + 30px);
    &.diagramhidden{
 margin-top:calc(#{$fixed-header-height} - 40px) !important;
    }
  }
  .hide-show-diagram {
    color: red;
    .secondary-nav-link{
      min-height:0px;
    }
  }
}
</style>
