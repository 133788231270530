<template>
  <div class="part-section-container">
    <div v-for="(categorie,index) in partCategoriesData"
    :key="Object.keys(categorie)[0] + index"
    class="part-group"
    >

      <div class="group-header kitSectionHeader Direct-Fit_Replacement_Exhaust_Kits">
        <h2>{{Object.keys(categorie)[0]}}</h2>
        <span class="num-selected"></span>
      </div>

      <div class="parts">
        <PartTile v-for="(part, catindex) in categorie[Object.keys(categorie)[0]]"
        :key="catindex+''+index+Object.keys(categorie)[0]"
        :partData='part'
        @click="partClicked(part)" />

      </div>
    </div>
  </div>
</template>

<script>

import { useMutations } from '@/lib/helpers'
import PartTile from './PartTile'
import { useRouter } from 'vue-router'
export default {
  props: { partCategoriesData: Array },
  components: { PartTile },
  setup (props) {
    const { setSelectedPart } = useMutations(['setSelectedPart'])
    const router = useRouter()
    const partClicked = (_clickedPart) => {
      setSelectedPart(_clickedPart)
      router.push({ name: 'PDP', params: { id: _clickedPart.product_part.number } })
    }
    return {
      partClicked
    }
  }
}
</script>

<style lang="scss">
    .part-section-container{
        display:flex;
        flex-direction: column;
        justify-content: center;
        max-width:1024px;
        width: 100%;
        .part-group{
            padding-top:30px;
            margin-top:30px;
            flex-grow:1;
            width: 100%;
            .group-header{
                text-align:left;
                display: flex;
                flex-direction: row;
                align-items: baseline;
                margin-bottom: 20px;
                h2{
                    margin: 0;
                    margin-right: 45px;
                    text-transform: uppercase;
                }span{
                    font-size:12px;
                }
            }
            .parts{
                width: 100%;
                display:flex;
                flex-direction:row;
                flex-wrap:wrap;
                justify-content:space-between;
            }
        }
    }
</style>
